const checkError = res => {
  if (res.status === 404) {
    throw new Error("404 uknown endpoint");
  }

  if (res.status === 500) {
    throw new Error("500 internal server error");
  }

  //   if (res.status === 400) {
  //     throw new Error("400 validation error");
  //   }
};
const post = async (url, payload) => {
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
      //   Authorization: `Bearer: ${auth_token}`
    }
  });
  // console.log(res);
  checkError(res);
  res = await res.json();
  if (res.status !== 200 && res.status_code !== "E433") {
    throw new Error(res.message);
  }
  return res;
};

const patch = async (url, payload, auth_token) => {
  let res = await fetch(url, {
    method: "PATCH",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer: ${auth_token}`
    }
  });
  checkError(res);

  res = await res.json();
  ///console.log(res);
  if (res.status !== 200) {
    throw new Error(res.message);
  }
  return res;
};

// eslint-disable-next-line no-unused-vars
const get = async (url, _payload = {}, auth_token) => {
  let res = await fetch(url, {
    headers: {
      Authorization: `Bearer: ${auth_token}`
    }
  });
  checkError(res);
  res = await res.json();
  if (res.status !== 200) {
    throw new Error(res.message);
  }
  return res;
};
export default {
  post,
  patch,
  get
};
