<template>
  <div class="content">
    <div class="md-layout md-alignment-top-center">
      <div
        class="md-layout-item md-medium-size-100 md-size-90 text-center"
        style="margin:20px;"
      >
        <p>
          Welcome to DPIE’s Virtual Permit Center an online resource to
          customers who visit the DPIE for Permit and License applications that
          are eligible for in-person review. This does not take the place of the
          online eplan process and applications will be denied at any time in
          the process if it is determined not to be eligible for in-person
          review.
        </p>
        <div style="border:1px solid #000; padding:20px">
          <p>
            Please complete the required fields to begin the application
            process. You will be placed in line and provided a number indicating
            your position in line. You will be contacted by email/text when you
            are next in line. A customer service representative may contact you
            by email/text to join a conference call during the initial intake
            process.
          </p>
          <p>Please monitor your Email/Text for the following:</p>
          <ul style="width:50%;margin:0 auto;list-style-type:none;">
            <li>PVC Ticket # and Position in Line #</li>
            <li>Where and When to Upload Plans</li>
            <li>Instructions to provide additional information</li>
            <li>
              Request to join a virtual session with a DPIE representative
            </li>
            <li>Application/Plan-Review status notifications</li>
            <li>Application completion and Payment notification</li>
          </ul>
          <p style="margin:10px">
            <input type="checkbox" @change="handleChange()" /> I have read these
            instructions
          </p>
        </div>
      </div>
      <div class="md-layout-item md-medium-size-100 md-size-90">
        <lookup-form :isChecked="isChecked" data-background-color="green">
        </lookup-form>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-size-90"
        style="margin:20px;"
      >
        <div>
          <p>Hours of Operation: 8:00am to 3:00pm M-F</p>
          <p>Closed on Prince George's County holiday</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
li:before {
  content: "■";
  margin-right: 10px; /* Adjust this to move text closer to the bullet */
}

li {
  text-indent: 10px; /* Aligns second line of text */
}
</style>

<script>
import { LookupForm } from "@/pages";

export default {
  components: {
    LookupForm
  },
  data: () => ({
    isChecked: false
  }),
  methods: {
    handleChange() {
      this.isChecked = !this.isChecked;
    }
  }
};
</script>
