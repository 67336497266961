var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('md-card',[_c('md-card-header',{attrs:{"data-background-color":_vm.dataBackgroundColor}},[_c('h4',{staticClass:"title"},[_vm._v("Applicant Self Check-in")])]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:errors[0] && 'md-invalid'},[_c('label',[_vm._v("First Name")]),_c('md-input',{attrs:{"type":"text","disabled":!_vm.isChecked},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:errors[0] && 'md-invalid'},[_c('label',[_vm._v("Last Name")]),_c('md-input',{attrs:{"type":"text","disabled":!_vm.isChecked},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"Country Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:errors[0] && 'md-invalid'},[_c('label',[_vm._v("Country Code")]),_c('md-select',{attrs:{"disabled":!_vm.isChecked},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}},[_c('md-option',{attrs:{"value":"+1"}},[_vm._v("+1")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-30"},[_c('ValidationProvider',{attrs:{"name":"Mobile No.","rules":"required|mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:errors[0] && 'md-invalid'},[_c('label',[_vm._v("Mobile No.")]),_c('md-input',{attrs:{"type":"text","disabled":!_vm.isChecked},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:errors[0] && 'md-invalid'},[_c('label',[_vm._v("Email Address")]),_c('md-input',{attrs:{"type":"email","disabled":!_vm.isChecked},model:{value:(_vm.emailadress),callback:function ($$v) {_vm.emailadress=$$v},expression:"emailadress"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-35"},[_c('ValidationProvider',{attrs:{"name":"ePermits Case ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:errors[0] && 'md-invalid'},[_c('label',[_vm._v("ePermits Case ID")]),_c('md-input',{attrs:{"type":"text","disabled":!_vm.isChecked},model:{value:(_vm.aliennumber),callback:function ($$v) {_vm.aliennumber=$$v},expression:"aliennumber"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[(!_vm.loading)?_c('md-button',{staticClass:"md-raised md-success",attrs:{"type":"submit","disabled":invalid || !_vm.isChecked}},[_vm._v("Submit")]):_vm._e(),(_vm.loading)?_c('md-button',{staticClass:"md-raised md-success",attrs:{"disabled":invalid || !_vm.isChecked}},[_c('md-progress-spinner',{attrs:{"md-diameter":15,"md-stroke":2,"md-mode":"indeterminate"}})],1):_vm._e(),(!_vm.isChecked)?_c('p',[_vm._v(" Please indicate that you have read the instructions before checking in to the Virtual Permit Center ")]):_vm._e()],1)])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }