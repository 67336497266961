<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="onSubmit">
      <md-card>
        <md-card-header :data-background-color="dataBackgroundColor">
          <h4 class="title">Applicant Self Check-in</h4>
          <!--           <p class="category" v-if="!$route.meta.lookup">Create</p>
          <p class="category" v-if="$route.meta.lookup">Search</p> -->
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="First Name"
                rules="required"
                v-slot="{ errors }"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>First Name</label>
                  <md-input
                    v-model="firstname"
                    type="text"
                    :disabled="!isChecked"
                  ></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="Last Name"
                rules="required"
                v-slot="{ errors }"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>Last Name</label>
                  <md-input
                    v-model="lastname"
                    type="text"
                    :disabled="!isChecked"
                  ></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="Country Code"
                v-slot="{ errors }"
                rules="required"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>Country Code</label>
                  <md-select v-model="code" :disabled="!isChecked">
                    <!-- <md-option value="+91">+91</md-option> -->
                    <md-option value="+1">+1</md-option>
                  </md-select>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-30">
              <ValidationProvider
                name="Mobile No."
                v-slot="{ errors }"
                rules="required|mobile"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>Mobile No.</label>
                  <md-input
                    v-model="phone"
                    type="text"
                    :disabled="!isChecked"
                  ></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>Email Address</label>

                  <md-input
                    v-model="emailadress"
                    type="email"
                    :disabled="!isChecked"
                  ></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>
            <!-- <div class="md-layout-item md-small-size-100 md-size-35">
              <ValidationProvider
                name="Alien Number"
                rules="required"
                v-slot="{ errors }"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>Alien Number</label>
                  <md-input v-model="aliennumber" type="text" :disabled="!isChecked"></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div> -->
            <div class="md-layout-item md-small-size-100 md-size-35">
              <ValidationProvider
                name="ePermits Case ID"
                rules="required"
                v-slot="{ errors }"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>ePermits Case ID</label>
                  <md-input
                    v-model="aliennumber"
                    type="text"
                    :disabled="!isChecked"
                  ></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>
            <!-- <div class="md-layout-item md-small-size-100 md-size-35">
              <ValidationProvider
                name="Date Of Birth"
                v-slot="{ errors }"
                rules="required"
              >
                <md-datepicker
                  :class="errors[0] && 'md-invalid'"
                  v-model="dob"
                  aria-placeholder="Date Of Birth"
                />
                <span class="md-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div> -->
            <!-- <div
              class="md-layout-item md-small-size-100 md-size-30"
              v-if="!$route.meta.lookup"
            >
              <ValidationProvider
                name="Appoinment Time Extension"
                v-slot="{ errors }"
                rules="required"
              >
                <md-field :class="errors[0] && 'md-invalid'">
                  <label>Appoinment Time Extension</label>
                  <md-select v-model="time" >
                    <md-option value="10">10</md-option>
                    <md-option value="20">20</md-option>
                    <md-option value="30">30</md-option>
                    <md-option value="40">40</md-option>
                    <md-option value="50">50</md-option>
                    <md-option value="60">60</md-option>
                    <md-option value="70">70</md-option>
                  </md-select>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div> -->
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                v-if="!loading"
                type="submit"
                :disabled="invalid || !isChecked"
                class="md-raised md-success"
                >Submit</md-button
              >

              <md-button
                v-if="loading"
                :disabled="invalid || !isChecked"
                class="md-raised md-success"
                ><md-progress-spinner
                  :md-diameter="15"
                  :md-stroke="2"
                  md-mode="indeterminate"
                ></md-progress-spinner
              ></md-button>
              <p v-if="!isChecked">
                Please indicate that you have read the instructions before
                checking in to the Virtual Permit Center
              </p>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import addSeconds from "date-fns/addSeconds";
import addMinutes from "date-fns/addMinutes";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";

import http from "../../utils/http";
extend("mobile", {
  validate(value) {
    const regEx = /^\d{10}$/;
    return {
      required: true,
      valid: value.match(regEx)
    };
  },
  computesRequired: true
});

/* extend("dob", {
  validate(value) {
    const regEx = /^\d{2}\/\d{2}\/\d{4}$/;
    return {
      required: true,
      valid: value.match(regEx)
    };
  },
  computesRequired: true
}); */
extend("email", email);
extend("required", required);
const ROOM = "1";
// const BASE_URL = window.env.VUE_APP_API_URL;
const BASE_URL2 = window.env.VUE_APP_API_URL2;
export default {
  name: "lookup-form",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    isChecked: Boolean,
    dataBackgroundColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      time: null,
      code: null,
      phone: null,
      emailadress: null,
      lastname: null,
      firstname: null,
      aliennumber: null,
      url: "appointment/create"
    };
  },
  mounted() {
    if (this.$route.meta.lookup) {
      this.url = "appointment/lookup";
    }
    this.$material.locale.dateFormat = "dd/MM/yyyy";
  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        this.reset();
      }

      if (to.meta.lookup) {
        this.url = "appointment/lookup";
      } else {
        this.url = "appointment/create";
      }
    }
  },
  methods: {
    reset() {
      this.firstname = null;
      this.lastname = null;
      this.code = null;
      this.phone = null;
      this.aliennumber = null;
      this.emailadress = null;
      this.dob = null;
      this.time = null;

      this.$nextTick(() => this.$refs.observer.reset());
    },
    async onSubmit() {
      if (!this.isChecked) {
        alert("Please accept the Terms & Conditions.");
        return;
      }

      try {
        const convertTime12to24 = time12h => {
          const [time, modifier] = time12h.split(" ");

          let [hours, minutes] = time.split(":");

          if (hours === "12") {
            hours = "00";
          }

          if (modifier === "PM") {
            hours = parseInt(hours, 10) + 12;
          }

          return [parseInt(hours, 10), parseInt(minutes, 10)];
        };

        const updateTime = responseTime => {
          const [time, modifier] = responseTime.split(" ");
          let [hours, minutes] = time.split(":");

          let convertedTime =
            parseInt(hours) - 1 + ":" + minutes + " " + modifier;

          return convertedTime;
        };

        this.loading = true;

        let post = {
          first_name: this.firstname,
          last_name: this.lastname,
          isd_code: this.code,
          phone: this.phone,
          alien_number: this.aliennumber,
          email: this.emailadress,
          appointment_time_extention: "0"
        };

        if (this.$route.meta.lookup) {
          delete post.appointment_time_extention;
          /*  delete post.isd_code;
          delete post.phone; */
        }

        const response = await http.get(BASE_URL2 + "/waitingroom/" + ROOM);

        // if (!this.$route.meta.lookup) {
        //   this.notify(response.message, "success");
        //   this.reset();
        // } else {
        const current = new Date();
        /*         const current_hour = current.getHours();
        const current_minutes = current.getMinutes(); */
        const { room_details } = response;
        const roominfo = room_details[0];
        const { start_time, end_time } = roominfo;

        const start_time_24 = convertTime12to24(start_time);
        const start_time_24_time = new Date();
        start_time_24_time.setHours(start_time_24[0] - 1, start_time_24[1]);

        const end_time_24 = convertTime12to24(end_time);
        const end_time_24_time = new Date(start_time_24_time);
        end_time_24_time.setHours(end_time_24[0] - 1, end_time_24[1]);

        /*         console.log(start_time_24_time, end_time_24, [
          current_hour,
          current_minutes
        ]);
        console.log(current >= start_time_24_time, current <= end_time_24_time); */

        if (current >= start_time_24_time && current <= end_time_24_time) {
          // start checkin process
          // this.notify(`Initiating check-in process`, "info");
          post = {
            room_id: ROOM, //! static for now, make it dynamic
            isd_code: this.code,
            phone: this.phone,
            last_name: this.lastname,
            first_name: this.firstname,
            email: this.emailadress,
            alien_number: this.aliennumber
          };
          // create customer in vwr portal
          await http.post(BASE_URL2 + "/customer", post);
          // this.notify(
          //   `${appointment_details.first_name} ${appointment_details.last_name} is checked-in successfully !!`,
          //   "success"
          // );
          this.$swal(
            `Checked-in successfully !!`,
            "An email/SMS has been sent to you with a link to start the verification process.",
            "success"
          );
        } else {
          this.notify(
            `Sorry for the inconvenience. Check-In is only available between ${updateTime(
              start_time
            )} to ${updateTime(end_time)}`,
            "info"
          );
        }
        // }
      } catch (e) {
        this.notify(e.message, "danger");
      } finally {
        this.loading = false;
      }
    },
    notify(message, type = "info") {
      this.$notify({
        message,
        icon: "add_alert",
        horizontalAlign: "bottom",
        verticalAlign: "center",
        type,
        timeout: 10000
      });
    },
    formatRemaining(left, type) {
      let add;
      if (type === "sec") {
        add = addSeconds;
      } else if (type === "min") {
        add = addMinutes;
      }
      try {
        const current = new Date();
        const duration = intervalToDuration({
          start: current,
          end: add(current, left)
        });
        return formatDuration(duration);
      } catch (e) {
        // console.log(e);
        return 0;
      }
    }
  }
};
</script>
<style></style>
