// import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

//import Dashboard from "@/pages/Dashboard.vue";
import AppoinmentLookUp from "@/pages/AppoinmentLookUp.vue";
import SelfCheckin from "@/pages/SelfCheckin.vue";
// import UserProfile from "@/pages/UserProfile.vue";
// import TableList from "@/pages/TableList.vue";
// import Typography from "@/pages/Typography.vue";
// import Icons from "@/pages/Icons.vue";
// import Maps from "@/pages/Maps.vue";
// import Notifications from "@/pages/Notifications.vue";
// import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";

const routes = [
  {
    path: "/",
    component: SelfCheckin,
    children: [
      // {
      //   path: "dashboard",
      //   name: "Dashboard",
      //   component: AppoinmentLookUp,
      //   meta: {
      //     hideFooter: true
      //   }
      // },
      {
        path: "lookup",
        name: "Dashboard",
        component: AppoinmentLookUp,
        meta: {
          hideFooter: true,
          lookup: true
        }
      }
    ]
  }
];

export default routes;
