<template>
  <div class="content">
    <div class="md-layout md-alignment-top-center">
      <div
        class="md-layout-item md-small-size-100 md-size-100"
        style="padding: 0"
      >
        <div class="md-layout">
          <div class="md-layout-item md-size-20 md-small-size-100">
            <img class="app-logo" src="@/assets/img/DPIE_Logo.png" />
          </div>
          <div
            class="
              md-layout-item md-size-80 md-small-size-100
              header-right-info
            "
          >
            <div class="parallelogram"></div>
            <div class="app-contact-info">
              <div class="md-layout nav-right">
                <div
                  class="md-layout-item md-small-size-100 md-size-85 info-div"
                >
                  <p>{{ $t("header_contact_1") }}</p>
                  <p>
                    <md-icon>call</md-icon>{{ $t("header_contact_2") }}
                    <span class="app-contact-info-higlight"
                      >(301) 636-2050</span
                    >
                  </p>
                  <p>
                    <md-icon>email</md-icon>{{ $t("header_contact_3") }}
                    <span class="app-contact-info-higlight"
                      >DPIE.VPC@co.pg.md.us</span
                    >
                  </p>
                </div>
                <div
                  class="
                    md-layout-item md-small-size-100 md-size-15
                    language-wrapper
                  "
                >
                  <span class="vbar"></span>
                  <md-field class="language-select">
                    <span class="md-prefix">
                      <span class="material-icons"> language </span>
                    </span>
                    <md-select v-model="language" name="language" id="language">
                      <md-option selected value="en">English</md-option>
                      <md-option value="es">Spanish</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="md-layout-item md-small-size-100 md-size-100 app-welcome-header"
        :class="{
          'is-language-en': $i18n.locale == 'en',
          'is-language-es': $i18n.locale == 'es'
        }"
      >
        <h3>{{ $t("header_welcome") }}</h3>
        <p>
          <span>{{ $t("header_text_start") }}</span>
          <a
            href="https://www.princegeorgescountymd.gov/departments-offices/permitting-inspections-and-enforcement/permits/walk-through-permit-process"
            target="_blank"
            class="inline-link"
            >{{ $t("header_text_inline_link") }}</a
          >
          <span>{{ $t("header_text_end") }}</span>
        </p>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-100 text-center">
        <h3>{{ $t("Working hours") }}:</h3>
        <ul>
          <li :key="key" v-for="([date, hrs], key) of schedule">
            <span>{{ $t(key) }}{{ date }}</span> |
            {{ hrs == "closed_holiday" ? $t(hrs) : hrs }}
          </li>
        </ul>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-100 app-content">
        <div
          class="
            md-layout-item md-small-size-100 md-size-100
            app-welcome-instructions
          "
        >
          <h3>{{ $t("instruction_heading") }}</h3>
          <p>
            {{ $t("instruction_text_primary_start") }}
            <a
              href="https://momentum.princegeorgescountymd.gov/home"
              target="_blank"
              class="inline-link"
              >{{ $t("instruction_text_primary_link") }}</a
            >
            {{ $t("instruction_text_primary_end") }}
          </p>
          <p>
            {{ $t("instruction_note") }}
          </p>
          <p>
            {{ $t("instruction_text_secondary") }}
          </p>
        </div>
        <!-- <div
          class="md-layout-item md-small-size-100 md-size-100 text-center"
          style="padding:20px"
        >
          {{ $t("instruction_follow") }}
        </div> -->
        <!-- <div class="md-layout-item md-small-size-100 md-size-100 text-center">
          <div class="md-layout instruction-btn-wrapper">
            <div class="md-layout-item">
              <div class="instruction-btn">
                <span>
                  <img src="@/assets/img/instruction_icon1.svg" />
                </span>
                <p>{{ $t("instruction_btn_text_1") }}</p>
              </div>
            </div>
            <div class="md-layout-item">
              <div class="instruction-btn">
                <span>
                  <img src="@/assets/img/instruction_icon2.svg" />
                </span>
                <p>{{ $t("instruction_btn_text_2") }}</p>
              </div>
            </div>
            <div class="md-layout-item">
              <div class="instruction-btn">
                <span>
                  <img src="@/assets/img/instruction_icon3.svg" />
                </span>
                <p>{{ $t("instruction_btn_text_3") }}</p>
              </div>
            </div>
            <div class="md-layout-item">
              <div class="instruction-btn">
                <span>
                  <img src="@/assets/img/instruction_icon4.svg" />
                </span>
                <p>{{ $t("instruction_btn_text_4") }}</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="md-layout-item md-small-size-100 md-size-100 text-center">
          {{ $t("please_read_text") }}
        </div>
        <div class="md-small-size-100 md-size-100 text-center">
          <md-button
            class="agree_btn"
            :md-ripple="false"
            @click="iagreeHandler()"
            :disabled="isChecked"
          >
            {{ $t("i_have_read_text") }}
          </md-button>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-100 text-center">
          <md-card
            class="
              md-layout-item md-size-50 md-small-size-100
              app-checkin-form-outer
            "
          >
            <md-card
              class="
                md-layout-item md-size-100 md-small-size-100
                app-checkin-form
              "
            >
              <h2>{{ $t("self_checkin_text") }}</h2>
              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="onSubmit">
                  <md-card-content>
                    <div class="md-layout md-gutter">
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="First Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <md-field :class="errors[0] && 'md-invalid'">
                            <label class="ellipsis-label">{{
                              $t("fname")
                            }}</label>
                            <md-input
                              v-model="firstname"
                              type="text"
                              :disabled="!isChecked"
                            ></md-input>
                            <span class="md-error error-message">{{
                              $t(errors[0])
                            }}</span>
                          </md-field>
                        </ValidationProvider>
                      </div>
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="Last Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <md-field :class="errors[0] && 'md-invalid'">
                            <label class="ellipsis-label">{{
                              $t("lname")
                            }}</label>
                            <md-input
                              v-model="lastname"
                              type="text"
                              :disabled="!isChecked"
                            ></md-input>
                            <span class="md-error error-message">{{
                              $t(errors[0])
                            }}</span>
                          </md-field>
                        </ValidationProvider>
                      </div>

                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="Country Code"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <md-field :class="errors[0] && 'md-invalid'">
                            <label class="ellipsis-label">{{
                              $t("country_code")
                            }}</label>
                            <md-select v-model="code" :disabled="!isChecked">
                              <!-- <md-option value="+91">+91</md-option> -->
                              <md-option value="+1">+1</md-option>
                            </md-select>
                            <span class="md-error error-message">{{
                              $t(errors[0])
                            }}</span>
                          </md-field>
                        </ValidationProvider>
                      </div>
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <label class="ellipsis-label field-label">{{
                          $t("epermitid")
                        }}</label>

                        <div class="md-layout md-gutter">
                          <div class="md-layout-item md-size-40 ptb0">
                            <ValidationProvider
                              name="Alien Number 1"
                              rules="required|aliennumber1"
                              v-slot="{ errors }"
                            >
                              <md-field
                                :md-counter="false"
                                :class="errors[0] && 'md-invalid'"
                              >
                                <label class="ellipsis-label t12">RES-00001</label>
                                <md-input
                                  v-model="aliennumber1"
                                  type="text"
                                  :disabled="!isChecked"
                                  maxlength="9"
                                  @keypress="isAlphaNumeric($event)"
                                ></md-input>
                                <span class="md-error error-message">{{
                                  $t(errors[0] && "(XXXXX)")
                                }}</span>
                              </md-field>
                            </ValidationProvider>
                          </div>
                          <div class="md-layout-item md-size-30 ptb0">
                            <span class="line-before"></span>
                            <ValidationProvider
                              name="Alien Number 2"
                              rules="required|aliennumber2"
                              v-slot="{ errors }"
                            >
                              <md-field
                                :md-counter="false"
                                :class="errors[0] && 'md-invalid'"
                              >
                                <label class="ellipsis-label t12">YEAR</label>
                                <md-input
                                  v-model="aliennumber2"
                                  type="text"
                                  :disabled="!isChecked"
                                  maxlength="4"
                                  @keypress="isNumeric($event)"
                                ></md-input>
                                <span class="md-error error-message">{{
                                  $t(errors[0] && "(XXXX)")
                                }}</span>
                              </md-field>
                            </ValidationProvider>
                          </div>
                          
                        </div>
                      </div>
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="Mobile No."
                          v-slot="{ errors }"
                          rules="required|mobile"
                        >
                          <md-field :class="errors[0] && 'md-invalid'">
                            <label class="ellipsis-label">{{
                              $t("mobile_no")
                            }}</label>
                            <md-input
                              v-model="phone"
                              type="text"
                              :disabled="!isChecked"
                              @keypress="isNumeric($event)"
                            ></md-input>
                            <span class="md-error error-message" v-if="phone">{{
                              $t(errors[0])
                            }}</span>
                          </md-field>
                        </ValidationProvider>
                      </div>

                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="email"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <md-field :class="errors[0] && 'md-invalid'">
                            <label class="ellipsis-label">{{
                              $t("email_add")
                            }}</label>

                            <md-input
                              v-model="emailadress"
                              type="email"
                              :disabled="!isChecked"
                            ></md-input>
                            <span
                              class="md-error error-message"
                              v-if="emailadress"
                              >{{ $t(errors[0]) }}</span
                            >
                          </md-field>
                        </ValidationProvider>
                      </div>
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="cmobile"
                          v-slot="{ errors }"
                          rules="required|confirm_mobile:@Mobile No."
                        >
                          <md-field :class="errors[0] && 'md-invalid'">
                            <label class="ellipsis-label">{{
                              $t("mobile_no_confirm")
                            }}</label>
                            <md-input
                              v-model="cphone"
                              type="text"
                              :disabled="!isChecked"
                              @keypress="isNumeric($event)"
                            ></md-input>
                            <span
                              class="md-error error-message"
                              v-if="cphone"
                              >{{ $t(errors[0]) }}</span
                            >
                          </md-field>
                        </ValidationProvider>
                      </div>
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="cemail"
                          v-slot="{ errors }"
                          rules="required|confirm_email:@email"
                        >
                          <md-field :class="errors[0] && 'md-invalid'">
                            <label class="ellipsis-label">{{
                              $t("email_add_confirm")
                            }}</label>

                            <md-input
                              v-model="cemailadress"
                              type="email"
                              :disabled="!isChecked"
                            ></md-input>
                            <span
                              class="md-error error-message"
                              v-if="cemailadress"
                              >{{ $t(errors[0]) }}</span
                            >
                          </md-field>
                        </ValidationProvider>
                      </div>
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="Walkthrough Type"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <md-field
                            :class="errors[0] && 'md-invalid'"
                            style="width:90%"
                          >
                            <label class="ellipsis-label">{{
                              $t("walkthrough_type")
                            }}</label>
                            <md-select
                              v-model="walkthroughType"
                              :disabled="!isChecked"
                            >
                              <md-option value="0">In-Person</md-option>
                              <md-option value="1">Virtual</md-option>
                            </md-select>
                            <span class="md-error error-message">{{
                              $t(errors[0])
                            }}</span>
                          </md-field>
                        </ValidationProvider>
                      </div>
                      <div class="md-layout-item md-size-50 md-small-size-100">
                        <ValidationProvider
                          name="Case Type"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <md-field
                            :class="errors[0] && 'md-invalid'"
                            style="width:90%"
                          >
                            <label class="ellipsis-label">{{
                              $t("case_type")
                            }}</label>
                            <md-select
                              v-model="caseType2"
                              :disabled="!isChecked"
                            >
                              <md-option value="0">
                                Residential Building Permit
                              </md-option>
                              <!-- <md-option value="1">
                                Residential Driveway Permit
                              </md-option> -->
                            </md-select>
                            <span class="md-error error-message">{{
                              $t(errors[0])
                            }}</span>
                          </md-field>
                        </ValidationProvider>
                      </div>

                      <div
                        class="
                          md-layout-item md-size-100 md-small-size-100
                          text-center
                        "
                        style="display: flex"
                        v-if="false"
                      >
                        <div
                          class="g-recaptcha"
                          style="margin: 0 auto"
                          v-show="isChecked"
                        >
                          <vue-recaptcha
                            @verify="onRecaptchaVerify"
                            @expired="onRecaptchaExpired"
                            sitekey="6Lcw7ckZAAAAAHYpUO7GY9VfV3IE3wd4enn_Z5Pt"
                            :loadRecaptchaScript="true"
                          ></vue-recaptcha>
                        </div>
                      </div>
                      <!-- <div class="md-layout-item md-size-100 md-small-size-100">
                        <ValidationProvider
                          name="Walkthrough Type"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <md-field
                            :class="errors[0] && 'md-invalid'"
                            style="width:50%"
                          >
                            <label class="ellipsis-label">{{
                              $t("walkthrough_type")
                            }}</label>
                            <md-select
                              v-model="walkthroughType"
                              :disabled="!isChecked"
                            >
                              <md-option value="0">In-Person</md-option>
                              <md-option value="1">Virtual</md-option>
                            </md-select>
                            <span class="md-error error-message">{{
                              $t(errors[0])
                            }}</span>
                          </md-field>
                        </ValidationProvider>
                      </div> -->
                      <div class="md-layout-item md-size-100 md-small-size-100">
                        <!-- <md-button class="md-raised checkin-btn"
                          >Check In</md-button
                        > -->

                        <md-button
                          v-if="!loading"
                          type="submit"
                          :disabled="
                            invalid || !isChecked || !isCaptchaVerified
                          "
                          class="md-raised checkin-btn"
                          >Check In</md-button
                        >

                        <md-button
                          v-if="loading"
                          :disabled="invalid || !isChecked"
                          class="md-raised checkin-btn"
                          ><md-progress-spinner
                            :md-diameter="15"
                            :md-stroke="2"
                            md-mode="indeterminate"
                          ></md-progress-spinner
                        ></md-button>
                      </div>
                      <div
                        v-if="!isChecked"
                        class="md-layout-item md-size-100 md-small-size-100"
                      >
                        {{ $t("please_indicate_text") }}
                      </div>
                    </div>
                  </md-card-content>
                </form>
              </ValidationObserver>
            </md-card>
          </md-card>
        </div>
        <div class="videoTutorialContainer">
          <md-card>
            <a href="" @click.prevent="toggleAccordian" class="accordian-link">
              <md-card-header class="tutorial-header">
                <div class="md-title">{{ $t("tutorial") }}</div>
                <div class="tutorial-icon">
                  <md-icon v-if="!open">add</md-icon>
                  <md-icon v-if="open">remove</md-icon>
                </div>
              </md-card-header>
            </a>

            <md-card-content v-bind:class="{ hide: !open }">
              <video width="100%" controls @contextmenu="handleContextMenu">
                <source v-bind:src="tutorialVideo" type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-100 app-footer">
        <div class="md-layout text-center">
          <!-- <div class="md-layout-item date-time">
            <strong>{{ now }}</strong>
          </div> -->
          <div class="md-layout-item copy-right">
            <strong>&copy; Copyright {{ year }}. All rights reserved</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
body {
  background-color: #fff !important;
}

.ellipsis-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
}
.ellipsis-label.field-label {
  font-size: 0.6875rem;
  color: #aaaaaa;
  float: left;
}

.error-message {
  text-align: left;
}

.language-select input[type="text"] {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  padding-left: 10px;
}
.md-list-item {
  margin-left: 0 !important;
}
.md-list-item-content {
  position: relative;
}
.md-list-item-text {
  position: absolute !important;
  left: 5% !important;
  top: 30% !important;
}

.md-scrollbar {
  overflow: hidden;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 10px;
}
ul li {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.ptb0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
}
.ptb0 .md-field {
  min-height: 20px;
  margin: 0;
  padding: 0;
}
.line-before {
  border-top: 3px solid #747474;
  width: 7px;
  position: absolute;
  left: -7px;
  height: 9px;
  top: 50%;
}
.md-field label.t12 {
  top: 12px;
}
.md-field.md-focused label.t12 {
  top: 12px;
}
.md-field.md-has-value label.t12 {
  top: 12px;
  display: none;
}
.md-card {
  width: 320px;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}

.videoTutorialContainer {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.accordian-link,
.accordian-link:active,
.accordian-link:hover,
.accordian-link:visited {
  color: #495057 !important;
}

.tutorial-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  margin: 0px !important;
  background-color: transparent !important;
}

.tutorial-header:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

@media only screen and (max-width: 500px) {
  .is-language-en {
    margin-top: 200px;
  }

  .is-language-es {
    margin-top: 220px !important;
  }
}

@media only screen and (max-width: 400px) {
  .is-language-en {
    margin-top: 200px;
  }

  .is-language-es {
    margin-top: 230px !important;
  }
}

@media only screen and (max-width: 325px) {
  .is-language-en {
    margin-top: 200px;
  }

  .is-language-es {
    margin-top: 260px !important;
  }
}

@media only screen and (max-width: 900px) {
  .is-language-en {
    margin-top: 200px;
  }

  .is-language-es {
    margin-top: 200px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .md-menu.md-select {
    padding-top: 0 !important;
  }
}

@media screen and (max-width: 900px) {
  .g-recaptcha > div > div {
    margin: 4px auto !important;
    text-align: center;
    width: auto !important;
    height: auto !important;
  }
  .language-wrapper {
    position: static !important;
    padding-top: 0px !important;
  }
  .app-contact-info p {
    text-align: center !important;
  }
  .info-div {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .videoTutorialContainer {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
<style lang="scss" scoped>
.app-logo {
  display: block;
  margin: 10px auto;
}
.header-right-info {
  background-color: #272643 !important;
  color: #fff !important;
  position: relative;
  height: auto;
  .parallelogram {
    position: absolute;
    left: -50px;
    top: 0;
    width: 150px;
    height: 100%;
    transform: skew(30deg);
    background-color: #272643;
  }
}
.app-contact-info {
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px;
  height: 100%;
  p {
    text-align: right;
    .md-icon {
      color: #fff;
      font-size: 14px !important;
    }
  }
  .app-contact-info-higlight {
    color: #ffdb45;
    font-weight: bold;
  }
}

.nav-right {
  background-color: #272643 !important;
}

.app-welcome-header {
  text-align: center;
  background-image: url(../assets/img/top_banner_background.png);
  background-size: cover;
  background-repeat: no-repeat;

  p {
    padding: 10px 20%;
    font-size: 1.1em;
    a {
      &.inline-link {
        text-decoration: underline;
        color: #1a1b4b;
        &:hover,
        &:active,
        &:visited,
        &:focus {
          text-decoration: underline !important;
          color: #1a1b4b !important;
        }
      }
    }
  }
}
.app-welcome-instructions {
  text-align: center;

  p {
    padding: 10px 20%;
    font-size: 1.1em;
    a {
      &.inline-link {
        text-decoration: underline;
        color: #1a1b4b;
        &:hover,
        &:active,
        &:visited,
        &:focus {
          text-decoration: underline !important;
          color: #1a1b4b !important;
        }
      }
    }
  }
}
.instruction-btn-wrapper {
  width: 60%;
  margin: 0 auto;
}
.instruction-btn {
  & > p {
    margin: 16px;
  }
  & > span {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    /* border: 1px solid #000000; */
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      flex: 1;
    }
  }
}
.agree_btn {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 7px;
  border: 2px solid #1a1b4b;
  /* padding: 5px;
  padding: 12px 18px; */

  font-size: 1em;
  max-width: 290px;
  &:hover {
    background-color: #fff !important;
    color: #000 !important;
  }
  &:active {
    background-color: #fff !important;
    color: #000 !important;
  }
  &:disabled {
    border-color: #7cc353;
    box-shadow: unset;
  }
}
.app-content {
  margin-bottom: 50px;
}
.app-checkin-form-outer {
  background: #c7cae6 !important;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
}
.app-checkin-form {
  padding: 20px;
  margin: 0;
  box-shadow: unset;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  position: relative;
  .md-card-content .md-layout-item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.checkin-btn {
  margin: 30px;
  background-color: #1a1b4b !important;
}
.app-footer {
  margin-top: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #000000;
  background-color: #f4f5f7;
  padding: 16px;
  .copy-right {
    text-align: center;
  }
}
.language-wrapper {
  position: relative;
  padding-top: 10px;
  .vbar {
    background-color: #fff;
    width: 1px;
    height: 80%;
    position: absolute;
    left: 0px;
    top: 4px;
  }
  &::before {
    content: " ";
    width: 1px;
    background-color: #fff;
    height: 10px;
  }
}

.language-select {
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 10px;

  .md-menu.md-select {
    padding-top: 4px;
  }

  .material-icons {
    padding-top: 10px;
    color: #fff;
  }

  &::after,
  &::before {
    height: 0;
  }
}
</style>
<script>
import "date-time-format-timezone";
import * as moment from "moment-timezone";
import VueRecaptcha from "vue-recaptcha";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import format from "date-fns/format";
import addSeconds from "date-fns/addSeconds";
import addMinutes from "date-fns/addMinutes";
// import isThisWeek from "date-fns/isThisWeek";
//  import startOfWeek from "date-fns/startOfWeek";
// import { toDate } from "date-fns-tz";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import http from "../utils/http";
extend("mobile", {
  validate(value) {
    const regEx = /^\d{10}$/;
    return {
      required: true,
      valid: value.match(regEx)
    };
  },
  computesRequired: true
});
extend("email", email);
extend("required", required);
extend("confirm_mobile", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Mobile confirmation does not match"
});

extend("confirm_email", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Email Address confirmation does not match"
});

extend("aliennumber1", {
  validate(value) {
    return String(value).length <= 9;
  },
  message: "(XXXXX)"
});

extend("aliennumber2", {
  validate(value) {
    return String(value).length == 4;
  },
  message: "(XXXX)"
});

extend("aliennumber3", {
  validate(value) {
    return String(value).length == 1;
  },
  message: "(X)"
});
const ROOM = "1";
const BASE_URL2 = window.env.VUE_APP_API_URL2;
const S3_BUCKET = window.env.VUE_APP_S3_BUCKET;
const ls = window.localStorage;
const week_map = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
};
//const date_format = "LLLL d, yyyy";
//const date_format = "MM/dd/yyyy";
function toDateWithOutTimeZone(date) {
  let tempTime = date.split(":");
  let dt = new Date();
  dt.setHours(tempTime[0]);
  dt.setMinutes(tempTime[1]);
  dt.setSeconds(tempTime[2]);
  return dt;
}

// const toDate = dateStr => {
//   const [year, month, day] = dateStr.split("-");
//   const d = +day;
//   return new Date(year, month - 1, d);
// };
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha
  },
  data: () => ({
    now: format(new Date(), "dd LLL, yyyy '|' hh:mm:ss aaa"),
    year: format(new Date(), "yyyy"),
    isChecked: false,
    loading: false,
    time: null,
    code: "+1",
    phone: null,
    cphone: null,
    emailadress: null,
    cemailadress: null,
    lastname: null,
    firstname: null,
    aliennumber: null,
    aliennumber1: null,
    aliennumber2: null,
    aliennumber3: null,
    url: "appointment/create",
    interval: null,
    language: "en",
    schedule: {
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: ""
    },
    timezone: "",
    isCaptchaVerified: true,
    open: false,
    tutorialVideo: `https://${S3_BUCKET}.s3.amazonaws.com/vpc_end_user.mp4`,
    walkthroughType: null,
    caseType2: null
  }),
  async mounted() {
    // console.log("k;l;lk;lk");
    if (ls.getItem("language")) {
      this.language = ls.getItem("language");
      this.$i18n.locale = ls.getItem("language");
    } else {
      const l = navigator.language.substring(0, 2) || "en";
      if (["es", "en"].includes(l)) {
        this.language = l;
      }
    }

    this.$material.locale.dateFormat = "dd/MM/yyyy";
    this.interval = setInterval(() => {
      this.now = format(new Date(), "dd LLL, yyyy '|' hh:mm:ss aaa");
    }, 1000);
    await this.getRoomInfo();
    moment.tz.setDefault(this.timezone);
    this.getWorkingHrsSchedule();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  watch: {
    language(lang) {
      this.$i18n.locale = lang;
      ls.setItem("language", lang);
    }
  },
  methods: {
    onRecaptchaVerify(e) {
      if (e) {
        this.isCaptchaVerified = true;
      }
    },
    onRecaptchaExpired() {
      window.location.reload();
    },
    reset() {
      this.firstname = null;
      this.lastname = null;
      this.code = "+1";
      this.phone = null;
      this.cphone = null;
      this.aliennumber = null;
      this.aliennumber1 = null;
      this.aliennumber2 = null;
      this.aliennumber3 = null;
      this.emailadress = null;
      this.cemailadress = null;
      this.dob = null;
      this.time = null;
      this.isChecked = false;
      this.$nextTick(() => this.$refs.observer.reset());
      this.walkthroughType = null;
      this.caseType2 = null;
    },
    async onSubmit() {
      if (!this.isChecked) {
        alert("Please accept the Terms & Conditions.");
        return;
      }

      try {
        this.loading = true;

        let post = {
          first_name: this.firstname,
          last_name: this.lastname,
          isd_code: this.code,
          phone: this.phone,
          alien_number:
            this.aliennumber1 +
            "-" +
            this.aliennumber2 ,
          email: this.emailadress,
          appointment_time_extention: "0",
          walkthrough_type: this.walkthroughType,
          case_type2: this.caseType2
        };

        if (this.$route.meta.lookup) {
          delete post.appointment_time_extention;
        }

        // start checkin process

        post = {
          room_id: ROOM, //! static for now, make it dynamic
          isd_code: this.code,
          phone: this.phone,
          last_name: this.lastname,
          first_name: this.firstname,
          email: this.emailadress,
          alien_number:
            this.aliennumber1 +
            "-" +
            this.aliennumber2 ,
          walkthrough_type: this.walkthroughType,
          case_type2: this.caseType2
        };
        // console.log({ http, BASE_URL2, post, token });
        // create customer in vwr portal
        const res = await http.post(BASE_URL2 + "/customer", post);
        this.reset();
        if (res.status_code === "E433") {
          this.$swal(
            this.$t("already_exist_heading"),
            this.$t("already_exist_msg"),
            "error"
          );
        } else {
          this.$swal(
            this.$t("success_heading"),
            this.$t("success_msg"),
            "success"
          );
        }

        // }
      } catch (e) {
        if (e.message.includes("already active")) {
          this.$swal(``, this.$t(e.message), "error");
        } else if (e.message.includes("maximum capacity")) {
          this.$swal(``, this.$t("maximum_capacity_error_msg"), "error");
        } else {
          this.$swal(``, this.$t("error_msg"), "error");
        }
      } finally {
        this.loading = false;
      }
    },
    notify(message, type = "info") {
      this.$notify({
        message,
        icon: "add_alert",
        horizontalAlign: "bottom",
        verticalAlign: "center",
        type,
        timeout: 10000
      });
    },
    formatRemaining(left, type) {
      let add;
      if (type === "sec") {
        add = addSeconds;
      } else if (type === "min") {
        add = addMinutes;
      }
      try {
        const current = new Date();
        const duration = intervalToDuration({
          start: current,
          end: add(current, left)
        });
        return formatDuration(duration);
      } catch (e) {
        // console.log(e);
        return 0;
      }
    },
    iagreeHandler() {
      this.isChecked = !this.isChecked;
    },
    async getRoomInfo() {
      try {
        const res = await fetch(`${BASE_URL2}/waitingroom/${ROOM}`);
        const data = await res.json();
        const { room_details } = data;
        const { time_zone } = room_details[0];
        // console.log(time_zone);
        this.timezone = time_zone || "America/New_York";
      } catch (e) {
        // console.log(e);
      }
    },

    startOfWeek(date) {
      var diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return new Date(date.setDate(diff));
    },

    getWeekDayArray() {
      try {
        // const opts = { timeZone: this.timezone };
        // let d = new Date().toLocaleString("en-US", opts);
        let dob1 = moment()
          .startOf("isoWeek")
          .toDate();
        let m = dob1.getMonth();
        m = parseInt(m) + 1;
        let dd = dob1.getDate();

        let week = [
          {
            1:
              this.checkIfSingleDigit(m) +
              "/" +
              this.checkIfSingleDigit(dd) +
              "/" +
              dob1.getFullYear()
          }
        ];

        for (let i = 1; i <= 6; i++) {
          let a = {};
          // a['day'] = (i+1);
          let date1 = new Date();

          date1.setDate(dob1.getDate() + i);
          // let final = new Date(date1).toLocaleString("en-US", {
          //   timeZone: this.timezone
          // });

          let final = moment(dob1)
            .day(i + 1)
            .toDate();
          m = final.getMonth();
          dd = this.checkIfSingleDigit(final.getDate());
          m = m + 1;
          m = this.checkIfSingleDigit(m);
          a[i + 1] = m + "/" + dd + "/" + final.getFullYear();
          week.push(a);
        }
        return week;
      } catch (e) {
        // console.log("getWeekDayArray", e);
      }
    },

    getWeekDayArrayAlternate() {
      try {
        // let d = new Date().toLocaleString("en-US", { timeZone: this.timezone });
        let dob1 = moment()
          .startOf("isoWeek")
          .toDate();
        let m = dob1.getMonth();
        m = parseInt(m) + 1;
        let dd = dob1.getDate();

        let week = [
          {
            day: 1,
            date:
              this.checkIfSingleDigit(m) +
              "/" +
              this.checkIfSingleDigit(dd) +
              "/" +
              dob1.getFullYear()
          }
        ];

        for (let i = 1; i <= 6; i++) {
          let a = {};
          a["day"] = i + 1;
          let date1 = new Date();

          date1.setDate(dob1.getDate() + i);
          let final = new Date(date1).toLocaleString("en-US", {
            timeZone: this.timezone
          });
          final = new Date(final);
          m = final.getMonth();
          dd = this.checkIfSingleDigit(final.getDate());
          m = m + 1;
          m = this.checkIfSingleDigit(m);
          a["date"] = m + "/" + dd + "/" + final.getFullYear();
          week.push(a);
        }
        return week;
      } catch (e) {
        // console.log("getWeekDayArrayAlternate", e);
      }
    },

    checkIfSingleDigit(val) {
      if (val < 10) {
        return "0" + val;
      }
      return val;
    },

    getListofDaysFromAPISchedule(data) {
      let mArray = [];
      if (data) {
        data.map(key => {
          mArray.push(key.week_day_id);
        });
      }
      return mArray;
    },

    async getWorkingHrsSchedule() {
      const weekArray = this.getWeekDayArray();
      try {
        const schedules = {};
        // const excption_dates = {};
        const res = await fetch(`${BASE_URL2}/schedule/${ROOM}`);
        const data = await res.json();
        const { room_schedule, room_holiday, exceptions } = data;

        const { schedule } = room_schedule;

        // const current_date = new Date();

        // const dayofweek = getDay(current_date);
        // const difffromcurrentdate = {
        //   Monday: 1,
        //   Tuesday: 2,
        //   Wednesday: 3,
        //   Thursday: 4,
        //   Friday: 5,
        //   Saturday: 6,
        //   Sunday: 7
        // };

        // for (const key in difffromcurrentdate) {
        //   difffromcurrentdate[key] =
        //     difffromcurrentdate[key] - parseInt(dayofweek);
        // }
        // const week_dates = [current_date];
        const listOfDaysFromApi = this.getListofDaysFromAPISchedule(schedule);
        schedule.map(({ week_day_id, end_time, start_time }) => {
          //let key = week_map[week_day_id];
          //let d = "";
          // if (week_day_id == dayofweek)
          // {
          //   d += " " + format(current_date, date_format);
          // }
          // else {
          //   const date = addDays(current_date, difffromcurrentdate[key]);
          //   week_dates.push(date);
          //   d += " " + format(date, date_format);
          // }
          weekArray.map(key => {
            if (!key[week_day_id]) {
              return;
            }
            let dateFormat = " " + key[week_day_id];

            if (listOfDaysFromApi.indexOf(week_day_id) != -1) {
              schedules[week_map[week_day_id]] = [
                dateFormat,
                `${format(
                  toDateWithOutTimeZone(start_time),
                  "hh:mm aaa"
                )} - ${format(toDateWithOutTimeZone(end_time), "hh:mm aaa")}`
              ];
            }
          });
        });

        room_holiday.map(item => {
          let splittedDate = item.holiday_date.split("T")[0];
          let splitMonthDateYear = splittedDate.split("-");
          let convertedDate =
            splitMonthDateYear[1] +
            "/" +
            splitMonthDateYear[2] +
            "/" +
            splitMonthDateYear[0];
          let formattedDate = " " + convertedDate;
          //const date = new Date(item.holiday_date);
          // if (isThisWeek(date)) {
          //   let day = getDay(date);
          //   day = day === 0 ? 7 : day;
          //const d = " " + format(date, date_format);
          const weekArray = this.getWeekDayArrayAlternate();
          weekArray.map(key => {
            if (key.date == convertedDate)
              schedules[week_map[key.day]] = [formattedDate, "closed_holiday"];
          });
        });

        const new_exceptions = {};
        exceptions.schedule.map(({ exception_date, start_time, end_time }) => {
          if (new_exceptions[exception_date]) {
            new_exceptions[exception_date] += `, ${format(
              toDateWithOutTimeZone(start_time),
              "hh:mm aaa"
            )} - ${format(toDateWithOutTimeZone(end_time), "hh:mm aaa")}`;
          } else {
            new_exceptions[exception_date] = `${format(
              toDateWithOutTimeZone(start_time),
              "hh:mm aaa"
            )} - ${format(toDateWithOutTimeZone(end_time), "hh:mm aaa")}`;
          }
        });

        for (let edate in new_exceptions) {
          let splittedDate = edate.split("T")[0];
          let splitMonthDateYear = splittedDate.split("-");
          let convertedDate =
            splitMonthDateYear[1] +
            "/" +
            splitMonthDateYear[2] +
            "/" +
            splitMonthDateYear[0];
          let formattedDate = " " + convertedDate;
          // const date = toDate(edate);
          // if (isThisWeek(date)) {
          //   const day = getDay(date);
          //   let key = week_map[day];
          //   //let d = " " + format(date, date_format);
          //   schedules[key] = [convertedDate, new_exceptions[edate]];
          // }
          const weekArray = this.getWeekDayArrayAlternate();
          weekArray.map(key => {
            if (key.date == convertedDate)
              schedules[week_map[key.day]] = [
                formattedDate,
                new_exceptions[edate]
              ];
          });
        }

        this.schedule = { ...schedules };
      } catch (e) {
        //console.log(e);
      }
    },

    toggleAccordian() {
      this.open = !this.open;
      if (this.open) {
        setTimeout(() => {
          window.scrollTo(0, document.body.clientHeight);
        }, 500);
      }
    },

    handleContextMenu(e) {
      e.preventDefault();
      return false;
    },

    isNumeric(e) {
      e = e ? e : window.event;
      const charCode = e.which || e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    isAlphaNumeric(event) {
    const charCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(charCode);
    // Allow alphanumeric characters, hyphen, and the following special characters: !@#$%^&*(),.?":{}|<>
    const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>-]*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) {
        return true;
      } else e.preventDefault(); // If not match, don't add to input text
    }
  }
};
</script>
